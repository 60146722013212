const Alphabet = {
  'a': '•-', 'b': '-•••', 'c': '-•-•', 'd': '-••',
  'e': '•', 'f': '••-•', 'g': '--•', 'h': '••••',
  'i': '••', 'j': '•---', 'k': '-•-', 'l': '•-••',
  'm': '--', 'n': '-•', 'o': '---', 'p': '•--•',
  'q': '--•-', 'r': '•-•', 's': '•••', 't': '-',
  'u': '••-', 'v': '•••-', 'w': '•--', 'x': '-••-',
  'y': '-•--', 'z': '--••', ' ': '/',
  '1': '•----', '2': '••---', '3': '•••--', '4': '••••-',
  '5': '•••••', '6': '-••••', '7': '--•••', '8': '---••',
  '9': '----•', '0': '-----',
}


export function MorseCanBeEncrypted(c) {
  return c.toLowerCase() in Alphabet;
}

export function MorseEncrypt(c, config) {
  return Alphabet[c.toLowerCase()];
}
